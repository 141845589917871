.menuHeader{
    top:0;
    left:0;
    height:30vh;
    background-attachment: fixed;
    width:100%;
    background-image: linear-gradient(rgba(0,0,0,0.5),rgba(0,0,0,0.5)),url("./pic_fauteuil.jpg");
    background-position: center;
    background-size: cover;
}
@media screen and (max-width:660px){
    .menuHeader{
        height:40vh;
        background-size: contain;
        background-image: linear-gradient(rgba(0,0,0,0.7),rgba(0,0,0,0.5)),url("./pic_fauteuil.jpg");
    }
}
.menuTitle{
    padding-right:auto;
    padding-left: auto;
    margin-top:50px;
    font-size:300%;
    color:#fff;
    display: inline-block;
    border-bottom:2px solid #ff652f;
    font-weight: 300;
}

.menu-options{
    margin-top:60px;
}

.bigtitle {
    font-family: 'friz-quadrata';
    font-size: 4rem;
    font-weight: bolder;
    color: white; /* #405b7c; blue menu*/
    text-align: center;
  }