.traiteurAll {
    padding: 5px auto;
    animation: fadein 4s;
    -moz-animation: fadein 4s; /* Firefox */
    -webkit-animation: fadein 4s; /* Safari et Chrome */
    -o-animation: fadein 4s; /* Opera */
}

@font-face {
    font-family: 'friz-quadrata';
    src: local('friz-quadrata'), url(../../../fonts/friz-quadrata-std-medium-5870338ec7ef8.otf) format('opentype');
  }

.head-text {
  height: 300px;
  background-image: url("/src/Assests/images/traiteur/head.jpg");
  background-repeat: no-repeat;
  -webkit-backdrop-filter: blur(15px); /* safari */
  backdrop-filter: blur(15px);
  color:white;
  padding-top: 80px;
}

.chief-text {
  height: 300px;
  background-color: #445f7d;
  background-image: url("/src/Assests/images/traiteur/chef.jpg");
  background-repeat: no-repeat;
  background-size: 315px;
  color:white;
  padding: 80px 10px 10px 300px;
}

.devises {
  height: 350px;
  background-color: white;
  color: black;
}

.col-title {
  margin-bottom: 10px;
  color: #445f7d;
  font-weight:bolder;
}
.col1-text {
  background-image: url("/src/Assests/images/traiteur/col1.jpg");
  background-repeat: no-repeat;
  background-size: 300px;
  background-position: 30px 30px;
  font-size:medium;
}

.col2-text {
  background-image: url("/src/Assests/images/traiteur/col2.jpg");
  background-repeat: no-repeat;
  background-size: 300px;
  background-position: 30px 30px;
  font-size:medium;
}

.col3-text {
  background-image: url("/src/Assests/images/traiteur/col3.jpg");
  background-repeat: no-repeat;
  background-size: 300px;
  background-position: 30px 30px;
  font-size:medium;
}

.map-text {
  height: 200px;
  width: 100%;
  background-color: #445f7d;
  background-image: url("/src/Assests/icons/map.png");
  background-repeat: no-repeat;
  background-size:80px;
  background-position: 100px 50px;
  color: white;
  padding: 60px 10px 10px 320px;
}

.traiteur-text {
  height: 325px;
  background-image: url("/src/Assests/images/traiteur/traiteur.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  color: white;
}

@media  screen and (max-width:480px){
  .head-text {
    padding: 10px 5px 10px 5px;
    height: 180px;
    font-size: small;
    text-align:start;
  }

  .map-text {
    background-size:70px;
    background-position: 10px 50px;
    padding: 30px 10px 10px 100px;
    height: 170px;
    font-size: small;
    text-align: start;
  }

  .chief-text {
    height: 280px;
    font-size: small;
    background-size: 150px;
    color:white;
    padding: 0px 10px 10px 160px;
    text-align: start;
  }

  .devises {
    height: 950px;
    background-color: white;
    color: black;
  }

  .traiteur-text {
    padding: 30px 10px 10px 10px;
    font-size: small;
    text-align: start;
  }
  
}