.menuAll{
    padding:20px auto;
}

@font-face {
    font-family: 'friz-quadrata';
    src: local('friz-quadrata'), url(../../../fonts/friz-quadrata-std-medium-5870338ec7ef8.otf) format('opentype');
  }

.grid-container {
    display: grid;
    align-content: space-evenly;
    grid-template-columns: auto auto;
    padding: 10px;
    font-family: friz-quadrata;
    color: #405b7c;
  }
  
  .grid-item, .grid-item-img {
    padding: 5px;
    font-family: friz-quadrata;
    font-size: 1.7vw;
    border: #405b7c 2px solid;
    margin: 15px;
    animation: fadein 4s;
    -moz-animation: fadein 4s; /* Firefox */
    -webkit-animation: fadein 4s; /* Safari et Chrome */
    -o-animation: fadein 4s; /* Opera */
  }

  .grid-item-img {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .img-menu {
/** **/
  }

  .carte {
    text-align: left;
  }

  .carte .mainlist {
    list-style-type:none;
  }

  .carte .mainlist li:before {
    content: '- ';
  }

  .carte .mainlistDrink li:before {
    content: '';
  }

  li {
    list-style-type: none;
  }

  .carte .sublist li:before {
    content: '';
  }

  .sublist {
    list-style-type: lower-alpha;
    list-style-position: inside;
    padding-left: 20px;
  }

  .title {
    display: inline-block;
    width: 100%;
    font-weight: bold;
    font-size: 2vw;
    text-align: center;
    border-bottom: #405b7c 2px solid;
  }

  i {
    font-size: 1.2vw;
    font-weight: normal;
  }

  .prix {
    float: right;
    font-size: 0.8em;
  }

  .products {
    font-family: friz-quadrata;
    color: #405b7c;
    font-size: 1.5vw;
    text-align: center;
  }

  .ico-menu {
    width: 40px;
  }

  .b-color {
    color: #ed6538;
    font-weight: bold;
    font-size: 1.2vw;
  }

  .media-change {
    display: inline-block;
  }

  @-moz-keyframes fadein { /* sur Firefox */
    from {
        opacity:0;
    }
    to {
        opacity:1;
    }
}
@-webkit-keyframes fadein { /* sur Safari et Chrome */
  from {
    opacity:0;
}
to {
    opacity:1;
}
}
@-o-keyframes fadein { /* sur Opera */
  from {
    opacity:0;
}
to {
  opacity:1;
}

}
@media  screen and (min-width:600px){
.menu {
    width: 100%;
    height: 1083px;
    background-repeat: no-repeat;
    background-position: center;
}
}

@media  screen and (max-width:600px){
    .menu {
        width: 100%;
        height: 1633px;
        background-repeat: no-repeat;
        background-position: center;
    }

    .grid-item-img, .img-menu {
      display: none;
    }

    .grid-container {
      display: grid;
      align-content: space-evenly;
      grid-template-columns: auto;
      padding: 10px;
      font-family: friz-quadrata;
      color: #405b7c;
    }

    .grid-item {
      font-size: 4vw;
    }

    i {
      font-size: 3vw;
    }

    .title {
      font-size: 4.3vw;
    }

    .products {
      font-size: 3.8vw;
    }

    .media-change {
      display: none;
    }

    .b-color {
      font-size: 3vw;
    }
    }