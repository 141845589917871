.stacked {
    position: relative;
    margin-top:30px;
    height:155px;
    width: 255px;
    box-shadow: 0 5px 5px #5f5f5f;
    overflow: hidden;

}
.stacked img{
    position: absolute;
    width:100%;
    top:0;
    left:0;
    opacity: 0.8;
    transition: all 0.4s ease;
}

.stacked img:hover{
    box-shadow: 0 20px 20px #5f5f5f;
    opacity: 1;
    z-index:2;
    opacity: 1;
}

.stacked-container{
    margin-top:10px;
}