.brand{
    display: inline-block;
    background-color: whitesmoke;
    opacity: 0.7;
    z-index:50;
    border:1px solid whitesmoke;
    height: 0;
    width: 0;
    padding:20px 60px;
    overflow: hidden;
    border-radius: 1px;
    margin-top:12vh;
}

.list-horaire {
    display: list-item;
    list-style: none;
    margin-top: 15px;
}

.list-horaire li {
    list-style: none;
}

.brandnum{
    display: inline-block;
    color: #14A76C;
    font-weight: bold;
    font-size:600%;
    padding-right:10px;
}
.name{
    font-size:320%;
    vertical-align: text-bottom;
    font-weight: 300;
    text-shadow:2px  #FFE400;
    color:#FFE400;
    display: inline-block;
}

.Slideshow {
    height: 600px;
    overflow: hidden;
    }

    .Slideshow-Content {
        position: relative;
        height: 100%;
    }
       .Slideshow-Content  img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            animation-name: fadeInOut;
            animation-timing-function: ease-in-out;
            animation-iteration-count: infinite;
            animation-duration: 15s;
            animation-direction: alternate;
        }
        .un{
            animation-delay: 40s;
        }
        .deux{
            animation-delay: 30s;
        }
        .trois{
            animation-delay: 20s;
        }
        .quatre{
            animation-delay: 10s;
        }
        .cinq{
            animation-delay: 0s;
        }

    @keyframes fadeInOut {
        0% {
            opacity:0;
        }
        30% {
            opacity:0;
        }
        35% {
            opacity:1;
        }
        65% {
            opacity:1;
        }
        70% {
            opacity:0;
        }
        100% {
            opacity:0;
        }
    }
@media screen and (max-width:550px){
    .name{
        font-size: 280%;
        
    }
    .brandnum{
        font-size:510%;
    }
    .brand img{
        margin-top:30px;
    }

    .Slideshow {
       display: none;
    }

    .elements {
        font-weight: normal;
        color: black;
        }

    .elements img {
    width: 25px;
    }

    .contact, tr, td {
    width: 100%;
    margin: auto;
    display: block;
    }

    .contact td {
    position: relative;
    }

    .contact td:before {
        position: absolute;
    }

    .contact {
        margin-bottom: 5px;
    }

    .time {
        border: 1px solid black;
    }
    .time, tr, td {
      display: block;
    }
    .time td {
        position: relative;
    }
    .time td:before {
        position: absolute;
    }
    .time .jour {
        font-weight: bold;
    }
    .time tr {
        margin-top: 3px;
        border: 1px solid black;
    }
    tbody {
        display: block;
    }
    }

@media screen and (min-width:550px) {

.brand img{
    margin-top: -100px;
    height:100%;
    width: auto;
    display: inline-block;
    border-radius: 5px;
}

.image-container{
    margin: -100px;
    padding:0;
    height:90px;
    display:block;
    width:auto;
}
.lastTitle{
    display: inline-block;
    padding-top:110px;
    padding-left:150px;
    font-weight: 400;
    color:#14A76C;
    font-size:130%;
}

.elements {
    font-weight: normal;
    color: black;
    text-align: left;
}

.elements img {
    width: 25px;
}

.jour {
    text-align: left;
    width: 200px;
}

.heures {
    text-align: center;
    width: 150px;
}

.contact {
    width: 100%;
    margin: auto;
}

.contact td {
    width: 33%;
    text-align: center;
}

.time {
    margin: 20px auto;
    border: 1px solid black;
}

.time td {
    border: 1px solid black;
    padding: 3px;
}

.plats {
    font-weight: bold;
    font-size: large;
}
}