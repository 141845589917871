.Alerts {
    top:0;
    left:0;
    height:20%;
    color: white;
    width:100vw;
   background-color: #ecb254;
    font-size:1.2em;
    display: none; /* toogle */
}

.flag {
    width: 50px;
    margin : -5px auto auto 30px;
}