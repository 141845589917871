.controls{
    padding:5px 15px;
    position: absolute;
    top:90vh;
}
.circle{
    display: inline-block;
    margin:0 3px;
    height: 17px;
    width:17px;
    border-radius: 50%;
    border:1px solid #ff652f;
    transition: 0.4s ease all;
    cursor: pointer;
}
.circle:hover{
    background-color: #ff652f;
}
 .full{
    display: inline-block;
    margin:0 3px;
    height: 17px;
    width:17px;
    border-radius: 50%;
    border:1px solid #ff652f;
    transition: 0.4s ease all;
    cursor: pointer;
    background-color: #ff652f;
}
.internal-c{
    padding-left:45vw;
}
.sec-tiles{
    margin-top: 60px;
} 
.specs{
    margin-top:80px;
    background-color: white;
    padding-bottom:20px;
    padding-top: 20px;
}

.specs2{
    margin-top:50px;
    background-color: #f5f5f5;
    padding-bottom:20px;
}
.specOffer{
    margin-bottom:50px;
}
.sec-map{
    margin-top:80px;
    display: block;
    padding-bottom:1vh;
    position: static;
}

.container-indic-time{
    width: 100%;
    text-align: center;
}
.indic-time {
    margin: auto;
    font-weight: bold;
}
@media screen and (max-width:1170px){
    .Header{
        background-size: contain;
        background-color: #445f7d;
    }
} 
@media screen and (max-width:700px){
    .Header{
        background-size: contain;
        background-color: #445f7d;
    }
@media screen and (max-width:550px){

    .Header{
        background-attachment: fixed;
        width: 100%;
        background-position: center;
        background-size: cover;
    }
    .specs {
        margin-top:135px;
    }
}
} 